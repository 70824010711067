<template>
	<div
		class="flex-grow flex h-full flex-col justify-between mx-auto rounded-none border-l border-l-mine-shaft border-r border-r-mine-shaft sm:max-w-[488px]"
	>
		<div class="flex h-[99px]">
			<div class="flex-1 border-b border-b-mine-shaft" />
			<div class="flex-1 border-b border-b-mine-shaft border-l border-l-mine-shaft" />
		</div>
		<div class="flex-grow px-5 sm:px-[72px] text-center py-15">
			<div class="text-[64px] font-bold leading-[68px] mb-[80px]">404</div>
			<div class="text-24 font-medium leading-[24px] mb-[80px]">Страница не найдена</div>

			<el-button class="w-[347px]" @click="$router.replace({ name: 'DocumentPackages' })">
				<ItIcon name="arrow-left" />
				<span>Вернуться</span>
			</el-button>
		</div>
		<div class="flex h-[99px]">
			<div class="flex-1 border-t border-t-mine-shaft" />
			<div
				class="flex-1 rounded-none border-l border-l-mine-shaft border-t border-t-mine-shaft"
			/>
		</div>
	</div>
</template>
